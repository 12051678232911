import React from "react";
import { Card } from "antd";

const ListAllGroupsPanel = ({ groups }) => {
  const visibleGroups = groups.filter((group) => group.canBeSeen);
  const hiddenGroups = groups.filter((group) => !group.canBeSeen);

  const sortedBestGroups = visibleGroups
    .filter((group) => group.prestigeRanking > 0 && group.logoUrl)
    .sort((a, b) => a.prestigeRanking - b.prestigeRanking);

  const newLength = Math.floor((sortedBestGroups.length + 1) / 5) * 5 - 1;

  const bestGroups = newLength && newLength >= 4 ? sortedBestGroups.slice(0, newLength) : sortedBestGroups;
  const excludedGroups = newLength && newLength >= 4 ? sortedBestGroups.slice(newLength) : [];

  const otherGroups = visibleGroups
    .filter((group) => !sortedBestGroups.find((bestGroup) => bestGroup?.name === group?.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <Card title="Visible Groups" style={{ flex: 1 }}>
        {!groups.length && <div>loading groups...</div>}
        {bestGroups.length > 0 && (
          <>
            <div>----- prestige groups -----</div>
            {bestGroups.map((group, idx) => (
              <div key={`best-${idx}`}>
                {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
              </div>
            ))}
          </>
        )}
        {excludedGroups.length > 0 && (
          <>
            <div style={{ marginTop: "16px" }}>----- other prestige groups -----</div>
            {excludedGroups.map((group, idx) => (
              <div key={`excld-${idx}`}>
                {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
              </div>
            ))}
          </>
        )}
        {otherGroups.length > 0 && (
          <>
            <div style={{ marginTop: "16px" }}>----- other groups -----</div>
            {otherGroups.map((group, idx) => (
              <div key={`other-${idx}`}>
                {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
              </div>
            ))}
          </>
        )}
      </Card>

      <Card title="Hidden Groups" style={{ flex: 1 }}>
        {!groups.length && <div>loading groups...</div>}
        {hiddenGroups
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((group, idx) => (
            <div key={idx}>
              {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
            </div>
          ))}
      </Card>
    </div>
  );
};

export default ListAllGroupsPanel;
