import configure from "./configure";
import stringify from "./query";

const { setHeadersWithAuth, axios } = configure;
export const getAllGroups = async ({ filterHidden }) => {
  const query = stringify({ filterHidden });
  return axios.get(`/groups${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getGroup = async ({ name, filterFields }) => {
  const query = stringify({ filterFields });
  return axios.get(`/groups/${name}${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getGroupById = async ({ id }) => {
  return axios
    .get(`/groups/id/${id}`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      },
    );
};

export const getGroupByNetworkGridId = async ({ gridId }) => {
  return axios.get(`/groups/networks-grid/${gridId}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getGroupByReportId = async ({ reportId }) => {
  return axios.get(`/groups/report/${reportId}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const createGroup = async ({ form }) => {
  const body = { form };
  return axios.post(`/groups`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const createUntitledGroup = async () => {
  return axios.post(`/groups/untitled`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateGroup = async ({ originalName, form }) => {
  const body = { originalName, form };
  return axios.patch(`/groups`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const deleteGroup = async ({ name }) => {
  return axios.delete(`/groups/${name}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const notifyValidation = async ({ group, email, verificationCode }) => {
  const body = { form: { group, email, verificationCode } };
  return axios.post(`/groups/notify-validation`, body).then();
};

export const importEmployeesCheckHeaders = async ({ json, token }) => {
  const body = { json };
  const headers = setHeadersWithAuth(token);
  return axios.post(`groups/import-employees-check-headers`, body, headers);
};

export const uploadEmployeesJSON = ({ json, name, reportId, year, isCensus, censusDate, token }) => {
  const body = { json, name, reportId, year, isCensus, censusDate };
  const headers = setHeadersWithAuth(token);
  return axios.post("groups/import-employees-json", body, headers);
};

export const importMedicalElectionCheckPlans = async ({ json, name, token }) => {
  const body = { json, name };
  const headers = setHeadersWithAuth(token);
  return axios.post(`groups/import-medical-election-check-plans`, body, headers);
};

export const uploadMedicalElectionJSON = ({ json, name, date, adjustments, token }) => {
  const body = { json, name, date, adjustments };
  const headers = setHeadersWithAuth(token);
  return axios.post("groups/import-medical-election-json", body, headers);
};

export const exportUsers = (group, token) => {
  const config = setHeadersWithAuth(token);
  return axios.get(`groups/export-users/${group}`, {
    headers: config.headers,
    responseType: "blob",
  });
};

export const exportEmployees = ({ name, date, year, columns, token }) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`groups/export-employees/${name}/${date}/${year}/${columns}`, {
    headers,
    responseType: "blob",
  });
};

export const checkIsMasterReport = (id, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`groups/is-master-report/${id}`, headers);
};

export const getPremiumTiers = ({ name, year, reportId, token }) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`groups/premium-tiers/${name}/${reportId}/${year}`, headers);
};

export const checkEmployeesWithMissingPremiumTier = ({ name, year, reportId, token }) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`groups/check-employees-with-missing-premium-tier/${name}/${reportId}/${year}`, headers);
};

export const deleteFromGroup = ({ name, id, token }) => {
  const body = { name, id };
  const headers = setHeadersWithAuth(token);
  return axios.post(`groups/delete-from-group/`, body, headers);
};

// unused for now, but we'll need in the future to migrate employees to their own collection
export const dataMigration = ({ save, token }) => {
  const body = { save };
  const headers = setHeadersWithAuth(token);
  return axios.post(`groups/data-migration`, body, headers);
};

export const checkEmployee = async ({ name, email, type }) => {
  const query = stringify({ name, email, type });
  return axios.get(`groups/check-employee${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};
