import React, { useState, useEffect } from "react";
import { getAllGroups } from "services/api/groups.api";
import { Tabs } from "antd";
import {
  ListAllGroupsPanel,
  AddGroupsPanel,
  UpdateGroupsPanel,
  ListGroupsStoredEmailsPanel,
  ListEmployeeJobOfferPanel,
  GroupsDataMigrationsPanel,
} from "components";

const AdminGroupsPage = () => {
  const { TabPane } = Tabs;

  const [activeKey, setActiveKey] = useState("1");
  const [groups, setGroups] = useState([]);

  const updateGroupsList = async () => {
    const { data } = await getAllGroups({ filterHidden: false });
    setGroups(data.groups.sort((a, b) => a.name.localeCompare(b.name)));
  };

  useEffect(() => {
    updateGroupsList();
  }, []);

  return (
    <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={(newActiveKey) => setActiveKey(newActiveKey)}>
      <TabPane tab="List All Groups" key="1">
        <ListAllGroupsPanel groups={groups} />
      </TabPane>
      <TabPane tab="Add Group" key="2">
        <AddGroupsPanel setActiveKey={setActiveKey} updateGroupsList={updateGroupsList} />
      </TabPane>
      <TabPane tab="Update Group" key="3">
        <UpdateGroupsPanel setActiveKey={setActiveKey} updateGroupsList={updateGroupsList} />
      </TabPane>
      <TabPane tab="Employee / Job Offer List" key="4">
        <ListEmployeeJobOfferPanel />
      </TabPane>
      <TabPane tab="Stored Emails" key="5">
        <ListGroupsStoredEmailsPanel />
      </TabPane>
    </Tabs>
  );
};

export default AdminGroupsPage;
