import configure from "./configure";
import stringify from "./query";

const { setHeadersWithAuth, axios } = configure;

// grabs all doctors / hospitals to create table
export const getAllOptionsForm = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("form", headers);
};

// form = {roomType, deliveryType, insurancePlanType, billIds, isDoctorInNetwork}
// called once form is completed
export const calculateForm = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("form", form, headers);
};

export const calculatePremiumForm = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("premium", form, headers);
};

export const getAllPlans = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("insurance-plans", headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updatePlans = ({ planIds, isPublic, form, token }) => {
  const headers = setHeadersWithAuth(token);
  const body = { planIds, isPublic, form };
  return axios.patch("insurance-plans/", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getPublicGroupsInsurance = (startDate) => {
  const query = stringify({ startDate });
  return axios.get(`public-plans/group${query}`);
};

export const getPublicPlansInsurance = (group, startDate) => {
  const query = stringify({ group, startDate });
  return axios.get(`public-plans/name${query}`);
};

export const getPublicNetworkInsurance = (group, name, startDate) => {
  const query = stringify({ group, name, startDate });
  return axios.get(`public-plans/network${query}`);
};

export const getGroupsInsurance = (userId, token, premium) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ userId, premium });

  return axios.get(`insurance-plans/group${query}`, headers);
};

export const getPlansInsurance = (group, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ group });

  return axios.get(`insurance-plans/name${query}`, headers);
};

// grab premium, hsa details
export const getNetworkInsurance = (group, name, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ group, name });
  return axios.get(`insurance-plans/network${query}`, headers);
};

export const getTiers = (group, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ group });

  return axios.get(`insurance-plans/tiers${query}`, headers);
};
