import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { PageTemplate, Header, GroupVerificationSteps } from "components";
import { getGroup } from "services/api/groups.api";

const GroupVerificationPage = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const groupFromQuery = new URLSearchParams(search).get("group");
  const [titleFromGroup, setTitleFromGroup] = useState(null);
  const [groupObj, setGroupObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const { group, title } = state || {};

  useEffect(() => {
    async function fetchGroup() {
      const res = await getGroup({ name: groupFromQuery || group, filterFields: true });
      if (res.status === 200) {
        setGroupObj(res.data.group);
        if (groupFromQuery) {
          setTitleFromGroup(res.data.group.displayName);
        }
        setLoading(false);
      }
    }
    fetchGroup();
  }, []);

  useEffect(() => {
    if ((groupObj && !groupObj.canBeSeen) || (!loading && !groupObj)) window.location.href = "/groups";
  }, [groupObj]);

  localStorage.removeItem("workEmail");
  localStorage.removeItem("jobOfferEmail");

  if (!group && !loading && !titleFromGroup) {
    history.push("/groups");
  }

  if (!groupObj) return null;
  return (
    <PageTemplate padding="0 12.5%" header={<Header V2 />}>
      {(title || titleFromGroup) && (
        <GroupVerificationSteps
          title={titleFromGroup ? titleFromGroup : title}
          group={groupFromQuery ? groupFromQuery : group}
          groupObj={groupObj}
        />
      )}
    </PageTemplate>
  );
};

export default GroupVerificationPage;
