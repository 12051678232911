import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getNetworksGrid, getReport, updateNetworksGrid, updateReport, updatePlans } from "services/api";
import { getRole } from "services/api/admin.api";
import { getGroup, updateGroup } from "services/api/groups.api";
import { getToken } from "services/utils";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "firebase/init";

import { EditOutlined } from "@ant-design/icons";
import { PageTemplate, Header, Footer, MyPlansDetailsCards, GroupFormMyPlansModal } from "components";
import { message, Form, Modal } from "antd";

const formatDate = (date) =>
  `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;

const CustomModal = styled(Modal)`
  min-width: 40vw;
  .ant-modal-body {
    padding-bottom: 24px;
  }

  @media (max-width: 1024px) {
    min-width: 90vw;
  }

  label {
    width: 100% !important;
  }
`;

const StickyButton = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  bottom: 40px;
  right: 40px;
  width: 276px;
  height: 90px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #21355b;
  border-radius: 30px;
  transition: 0.2s;

  :hover {
    color: white;
    background-color: #283e67;
    cursor: pointer;
  }
`;

const MyPlansDetailsPage = ({ ...props }) => {
  const { id, name } = props.match.params;
  const [networksGrid, setNetworksGrid] = useState(null);
  const [report, setReport] = useState(null);
  const [group, setGroup] = useState(null);
  const [modal, setModal] = useState(false);

  const [form] = Form.useForm();
  const doesntHaveLinkedinWatch = Form.useWatch("doesntHaveLinkedin", form);
  const [logo, setLogo] = useState(null);
  const [originalName, setOriginalName] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      const isAdminResponse = await getRole(token);
      setIsAdmin(isAdminResponse.status);

      const { status, data } = await getNetworksGrid({ id });
      status === 200 && setNetworksGrid(data.grid);

      const { status: reportStatus, data: reportData } = await getReport(id, token);
      reportStatus === 200 && setReport(reportData.report);

      const { status: groupStatus, data: groupData } = await getGroup({ name, filterFields: false });
      if (groupStatus === 200) {
        setGroup(groupData.group);
        setOriginalName(groupData.group.name);
      }

      if ((!status && !reportStatus) || !groupStatus) {
        props.history.push("/my-plans");
      }
    })();
  }, []);

  const handleUpdateGroup = async (changes) => {
    if (changes.linkedinUrl?.endsWith("/")) {
      changes.linkedinUrl = changes.linkedinUrl.slice(0, -1);
    }

    // for some reason eslint says there's a useless escape here, but if I remove it, it doesn't work anymore
    // eslint-disable-next-line no-useless-escape
    changes.name = changes.linkedinUrl && changes.linkedinUrl.match(/(?<=\/company\/)[^\/]+/gm)?.[0];
    if (!changes.name) {
      changes.name = group.name;
    }
    if (report && !group.masterReports.includes(report._id)) {
      changes.masterReports = [...group.masterReports, report._id];
    }

    changes.hasLinkedin = !changes.doesntHaveLinkedin;
    if (!changes.hasLinkedin && changes.ein?.length < 10) {
      message.error("Please enter a valid EIN");
      return;
    }

    if (changes.hasLinkedin) {
      changes.ein = "";
    } else {
      changes.linkedinUrl = "";
    }

    if (logo !== null) {
      try {
        const storageRef = ref(
          storage,
          // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_FB_CUSTOM_FOLDER_NAME}/${changes.name} - ${logo.name}`,
        );
        await uploadBytes(storageRef, logo);
        const url = await getDownloadURL(storageRef);
        changes.logoUrl = url;
        delete changes.logo;
        setLogo(null);
      } catch (error) {
        message.error("Error uploading logo");
        return;
      }
    }

    const response = await updateGroup({ originalName, form: changes });
    if (response.status === 200) {
      message.success("Group updated successfully");
      setModal(false);
      form.resetFields();
      if (response.data.group) {
        setGroup(response.data.group);
        setOriginalName(response.data.group.name);
        window.location.pathname = `/my-plans/${response.data.group.name}/${id}`;
      }
      if (changes.year !== report.year) {
        const token = await getToken();
        const reportStartDate = new Date(report.dates[0]);
        const reportEndDate = new Date(report.dates[1]);
        reportStartDate.setFullYear(changes.year);
        reportEndDate.setFullYear(changes.year);
        updatePlans({ planIds: report.birthParent.planIds, isPublic: false, form: { year: changes.year }, token });
        updateReport(
          report._id,
          { year: changes.year, dates: [formatDate(reportStartDate), formatDate(reportEndDate)] },
          token,
        );
        updateNetworksGrid({ id: networksGrid._id, form: { year: changes.year } });
      }
    } else {
      message.error(response.response.data.message);
    }
  };

  return (
    <PageTemplate
      header={
        <>
          <Header invert V2 />
        </>
      }
      footer={<Footer />}
    >
      {group && <MyPlansDetailsCards group={group} setGroup={setGroup} report={report} networksGrid={networksGrid} />}
      <StickyButton onClick={() => setModal(true)}>
        <EditOutlined style={{ padding: "0 15px 0 0" }} />
        EDIT GROUP
      </StickyButton>
      {group && (
        <CustomModal
          title={`Edit Group: ${group.displayName}`}
          open={modal}
          destroyOnClose={true}
          onCancel={() => {
            setModal(false);
          }}
          onOk={() => {
            handleUpdateGroup(form.getFieldsValue());
          }}
        >
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            preserve={false}
            initialValues={{
              displayName: group.displayName,
              otherSearchTerms: group.otherSearchTerms,
              otherDomains: group.otherDomains,
              linkedinUrl: group.linkedinUrl,
              doesntHaveLinkedin: !group.hasLinkedin,
              ein: group.ein,
              year: report?.year || networksGrid?.year,
            }}
          >
            <GroupFormMyPlansModal
              imageUrl={group.logoUrl}
              setLogo={setLogo}
              disableLinkedinUrl={group.hasPublishedReports}
              doesntHaveLinkedinWatch={doesntHaveLinkedinWatch}
              form={form}
              isAdmin={isAdmin}
            />
          </Form>
        </CustomModal>
      )}
    </PageTemplate>
  );
};

export default MyPlansDetailsPage;
