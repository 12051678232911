import React, { useState, useEffect } from "react";
import { PageTemplate, Header } from "components";
import { GroupsList, GroupsListHeader } from "components";
import { getAllGroups } from "services/api/groups.api";
import { getProfile, getReport } from "services/api";
import { getToken, getUserId } from "services/utils";

const GroupsListPage = () => {
  const [groups, setGroups] = useState([]);
  const [cardsToShow, setCardsToShow] = useState([]);

  const userCanAccessPage = async () => {
    const userId = await getUserId();
    const token = await getToken();
    if (userId) {
      const response = await getProfile(userId, token);
      const { status, data } = response;
      if (status === 200) {
        const user = data.user;
        const reportResponse = await getReport(user.currentReport, token);
        if (reportResponse.status === 200) {
          const currentReport = reportResponse.data.report;
          if (currentReport && currentReport.status !== 3) {
            window.location.href = "/";
          }
        }
      }
    }
  };

  const updateGroupsList = async () => {
    const { data } = await getAllGroups({ filterHidden: true });
    const groupsFromDatabase = [];
    for (const group of data.groups) {
      groupsFromDatabase.push({
        title: group.displayName,
        value: group.displayName,
        group: group.name,
        prestigeRanking: group.prestigeRanking,
        icon: group.logoUrl,
        terms: group.otherSearchTerms,
      });
    }
    setGroups(groupsFromDatabase.sort((a, b) => a.value.localeCompare(b.value)));
  };

  useEffect(() => {
    userCanAccessPage();
    updateGroupsList();
  }, []);

  useEffect(() => {
    if (groups.filter((group) => group.prestigeRanking > 0 && group.icon).length >= 1) {
      const bestGroups = groups
        .filter((group) => group.prestigeRanking > 0 && group.icon)
        .sort((a, b) => a.prestigeRanking - b.prestigeRanking);
      const newLength = Math.floor((bestGroups.length + 1) / 5) * 5 - 1;
      bestGroups.length = newLength
        ? newLength >= 4
          ? Math.floor((bestGroups.length + 1) / 5) * 5 - 1
          : bestGroups.length
        : 0;
      setCardsToShow([
        ...bestGroups.sort((a, b) => a.title.localeCompare(b.title)),
        {
          title: "Other...",
          value: "Add An Employer",
          icon: "https://cdn-icons-png.flaticon.com/512/992/992651.png",
          group: "no group",
          isAddNew: true,
        },
      ]);
    } else {
      setCardsToShow({
        title: "Other...",
        value: "Add An Employer",
        icon: "https://cdn-icons-png.flaticon.com/512/992/992651.png",
        group: "no group",
        isAddNew: true,
      });
    }
  }, [groups]);

  return (
    <PageTemplate padding="0 12.5%" header={<Header V2 />}>
      {Boolean(groups?.length) && (
        <>
          <GroupsListHeader options={groups.filter((option) => option.value !== "Add An Employer")} />
          <GroupsList options={cardsToShow} />
        </>
      )}
    </PageTemplate>
  );
};

export default GroupsListPage;
