import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { verifyEmail, verifyCode, groupAddPartner, getGroupReportInfo, checkEmployee } from "services/api";
import { notifyValidation } from "services/api/groups.api";
import { message } from "antd";
import { getToken, getUserId } from "services/utils";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/plan/actions";

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`;

const Button = styled.div`
  background-color: ${(props) => (props.disabled ? "#8e9fbd" : "#1c355e")}
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 4px 30px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  justify-self: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}};
  transition: 0.1s;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#8e9fbd" : "#2e528c")}};
  }
  @media (max-width: 900px){
    width: 150px;
    font-size: 16px;
  }
`;

const GroupVerificationFooter = ({
  option,
  jobOfferOption,
  step,
  setStep,
  setPreviousStep,
  email,
  canSendEmail,
  code,
  group,
  groupObj,
  setDatabaseErrorMessage,
}) => {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { reportId, partner } = state || {};
  email = email.replace("qu.edu", "quinnipiac.edu");
  const username = useSelector((state) => state.profile.username);

  const sendToCorrectFlow = async ({ reportId, partner, group, groupObj, newReport }) => {
    if (reportId || partner) {
      const workEmail = localStorage.getItem("workEmail");
      const jobOfferEmail = localStorage.getItem("jobOfferEmail");
      const { groupReportId, displayName, premiumTier, waiverTier } =
        (
          await getGroupReportInfo({
            group,
            additionalInformation: { workEmail, jobOfferEmail },
          })
        )?.data?.response || {};
      if (groupObj.skipFollowUpQuestions && groupObj.editable) {
        localStorage.removeItem("form");
        dispatch(Actions.Creators.changeCurrent(0));
        history.push("/edit-plan", {
          reportId,
          option: partner ? partner : "birthParent",
          reportToVerify: groupReportId,
          workEmail,
          jobOfferEmail,
          group: group,
          premiumTier: premiumTier,
          waiverTier,
          displayName,
        });
      } else if (
        (groupObj.skipFollowUpQuestions ||
          (groupObj.verifyWorkEmailAgainstDatabase && workEmail) ||
          (groupObj.verifyJobOfferAgainstDatabase && jobOfferEmail)) &&
        !groupObj.requiresUserPremiums
      ) {
        const userId = await getUserId();
        const partnerResponse = await groupAddPartner({
          reportId,
          partnerType: partner,
          additionalInformation: { workEmail, jobOfferEmail },
          group,
          userId,
          primary: email,
        });
        if (partnerResponse.status === 200) {
          history.push(`/optimizeV2/${reportId}`);
        } else {
          message.error("Something went wrong. Please, try again later.");
        }
      } else if (
        (groupObj.skipFollowUpQuestions ||
          (groupObj.verifyWorkEmailAgainstDatabase && workEmail) ||
          (groupObj.verifyJobOfferAgainstDatabase && jobOfferEmail)) &&
        groupObj.requiresUserPremiums
      ) {
        history.push("/add-premiums", {
          reportId,
          group,
          option: partner ? partner : "birthParent",
          reportToVerify: groupReportId,
          workEmail,
          jobOfferEmail,
          premiumTier: premiumTier,
        });
      } else {
        history.push("/typeform/new-recommendation", {
          url: `https://predictabill.typeform.com/to/jI2oRWzX?typeform-welcome=0&group=${group}&primary=${
            username || email
          }`,
          group: group || "no group",
          reportId: reportId,
          partner: partner,
          primary: username || email,
        });
      }
    } else {
      localStorage.removeItem("codeId");
      if (newReport) {
        history.push("/typeform/new-recommendation", {
          url: `https://predictabill.typeform.com/to/jI2oRWzX?typeform-welcome=0&primary=${
            username || email
          }&skipemail=1`,
          group: group || "no group",
          newReport: newReport,
          primary: username || email,
        });
      } else {
        history.push("/typeform/new-recommendation", {
          url: `https://predictabill.typeform.com/to/jI2oRWzX?typeform-welcome=0&primary=${
            username || email
          }&groupstart=1`,
          group: group || "no group",
          primary: username || email,
        });
      }
    }
  };

  const forward = async () => {
    const token = await getToken();
    if (email) {
      notifyValidation({ group, email, verificationCode: null });
    }
    const newReport = token ? true : false;
    if (step === 0) {
      if (groupObj.skipEmailVerification) {
        sendToCorrectFlow({
          reportId,
          partner,
          group,
          groupObj,
          newReport,
        });
      }
      setPreviousStep(step);
      setStep(step + 1);
    } else if (step === 1 && canSendEmail && groupObj.verifyWorkEmailAgainstDatabase) {
      setCheckingEmail(true);
      const {
        status,
        data: { isInDatabase },
      } = await checkEmployee({
        name: group,
        email: email.replace("qu.edu", "quinnipiac.edu"),
        type: "currentEmployee",
      });
      setCheckingEmail(false);
      if (status === 200 && isInDatabase) {
        message.success({
          content: <>{"Email verified! Proceeding..."}</>,
          icon: (
            <span
              role="img"
              aria-label="check-circle"
              className="anticon anticon-check-circle"
              style={{
                color: "#00a19b",
              }}
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="check-circle"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
              </svg>
            </span>
          ),
        });
        localStorage.setItem("workEmail", email);
        sendToCorrectFlow({
          reportId,
          partner,
          group,
          groupObj,
          newReport,
        });
      } else {
        message.error("Invalid email");
      }
    } else if (step === 1 && canSendEmail && !groupObj.requiresEmailVerification) {
      localStorage.setItem("workEmail", email);
      sendToCorrectFlow({
        reportId,
        partner,
        group,
        groupObj,
        newReport,
      });
    } else if (step === 1 && canSendEmail) {
      setSendingEmail(true);
      const response = await verifyEmail(email);
      if (response.status === 200) {
        setSendingEmail(false);
        const { codeId } = response.data;
        localStorage.setItem("codeId", codeId);
        setPreviousStep(step);
        setStep(step + 1);
      }
      setPreviousStep(step);
      setStep(step + 1);
    } else if (step === 2) {
      const response = await verifyCode(localStorage.getItem("codeId"), code);
      if (response.status === 200) {
        const { isValid } = response.data;
        if (isValid) {
          message.success({
            content: <>{"Email verified! Proceeding..."}</>,
            icon: (
              <span
                role="img"
                aria-label="check-circle"
                className="anticon anticon-check-circle"
                style={{
                  color: "#00a19b",
                }}
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="check-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                </svg>
              </span>
            ),
          });
          localStorage.setItem("workEmail", email);
          sendToCorrectFlow({
            reportId,
            partner,
            group,
            groupObj,
            newReport,
          });
        } else {
          message.error("Invalid code");
        }
      } else {
        message.error("Your verification code expired. Please, try again.");
        setPreviousStep(step);
        setStep(step - 1);
      }
    }
  };

  return (
    <FooterWrapper>
      <Button
        onClick={() => {
          if (step > 0) {
            setPreviousStep(step);
            setStep(step - 1);
          } else {
            history.push("/groups");
          }
        }}
      >
        <Text white center>
          {step === 2 && option === "currentEmployee" ? "< Change Email" : "< Back"}
        </Text>
      </Button>
      {option === "currentEmployee" ? (
        <Button disabled={(step === 1 && !canSendEmail) || sendingEmail || checkingEmail} onClick={() => forward()}>
          <Text white center>
            {step === 0
              ? `Next >`
              : step === 1
              ? sendingEmail
                ? "Sending..."
                : checkingEmail
                ? "Checking..."
                : "Next >"
              : "Continue >"}
          </Text>
        </Button>
      ) : option === "jobOffer" ? (
        <Button
          disabled={
            (step === 2 && jobOfferOption === "verificationEmail" && !canSendEmail) || sendingEmail || checkingEmail
          }
          onClick={async () => {
            const token = await getToken();
            if (code) {
              notifyValidation({ group, email: null, verificationCode: code });
            }
            const newReport = token ? true : false;
            if (step === 0) {
              setPreviousStep(step);
              setStep(step + 1);
            } else if (step === 1) {
              if (jobOfferOption === "upload") {
                window.location.href = `https://predictabill.typeform.com/to/E56Aa77w?typeform-welcome=0&email=${
                  username || ""
                }`;
              } else {
                setPreviousStep(step);
                setStep(step + 1);
              }
            } else if (step === 2) {
              if (jobOfferOption === "verificationCode") {
                const response = await verifyCode("jobOffer", code);
                if (response.status === 200) {
                  const { isValid } = response.data;
                  if (isValid) {
                    message.success({
                      content: <>{"Code verified! Proceeding..."}</>,
                      icon: (
                        <span
                          role="img"
                          aria-label="check-circle"
                          className="anticon anticon-check-circle"
                          style={{
                            color: "#00a19b",
                          }}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="check-circle"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                          </svg>
                        </span>
                      ),
                    });
                    sendToCorrectFlow({
                      reportId,
                      partner,
                      group,
                      groupObj,
                      newReport,
                    });
                  } else {
                    message.error("Invalid code");
                  }
                }
              } else if (jobOfferOption === "verificationEmail" && canSendEmail) {
                setCheckingEmail(true);
                const {
                  status,
                  data: { isInDatabase },
                } = await checkEmployee({
                  name: group,
                  email: email,
                  type: "jobOffer",
                });
                setCheckingEmail(false);
                if (status === 200 && isInDatabase) {
                  message.success({
                    content: <>{"Email verified! Proceeding..."}</>,
                    icon: (
                      <span
                        role="img"
                        aria-label="check-circle"
                        className="anticon anticon-check-circle"
                        style={{
                          color: "#00a19b",
                        }}
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="check-circle"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                        </svg>
                      </span>
                    ),
                  });
                  localStorage.setItem("jobOfferEmail", email);
                  sendToCorrectFlow({
                    reportId,
                    partner,
                    group,
                    groupObj,
                    newReport,
                  });
                } else {
                  setDatabaseErrorMessage(
                    "That email address is not in our database as a valid job offer with this employer. Please contact your recruiter to have your email address added.",
                  );
                }
              }
            }
          }}
        >
          <Text white center>
            {step === 0
              ? `Next >`
              : step === 1
              ? "Next >"
              : step === 2
              ? sendingEmail
                ? "Sending..."
                : checkingEmail
                ? "Checking..."
                : "Next >"
              : "Next >"}
          </Text>
        </Button>
      ) : (
        <Button
          disabled={false}
          onClick={async () => {
            window.location.href = "https://calendly.com/predictabill/help";
          }}
        >
          <Text white center>
            {`Next >`}
          </Text>
        </Button>
      )}
    </FooterWrapper>
  );
};

export default GroupVerificationFooter;
